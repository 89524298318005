const MauticInit = (
  mauticSrc: string,
  mauticDomain: string,
  submittingMessage: string
): void => {
  // if (!mauticSrc || !mauticDomain || !submittingMessage) return

  // if (typeof document === `undefined`) return

  // if (typeof window.MauticSDKLoaded === `undefined`) {
  //   window.MauticSDKLoaded = true
  //   window.MauticDomain = mauticDomain
  //   window.MauticLang = {
  //     submittingMessage: submittingMessage,
  //   }

  //   const head = document.querySelector(`head`)
  //   const script = document.createElement(`script`)
  //   script.type = `text/javascript`
  //   script.src = `${mauticSrc}`
  //   script.onload = function () {
  //     window.MauticSDK.onLoad()
  //   }
  //   head?.appendChild(script)
  // }
}

export default MauticInit
